<template>
  <div class="indicator">
    <div class="flex-col">
      <div class="points">
        {{ props.cmePoints.toString() }}
      </div>
      <div class="flex-row leading-3 text-[10px] md:text-[12px] center">
        {{ t('event.cmePoints') }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const { t } = useTrans();

const props = defineProps({
  cmePoints: {
    type: Number,
    required: true,
  },
});
</script>
<style lang="postcss">
.indicator {
  @apply w-[40px] md:w-[50px] h-[40px] md:h-[50px] rounded-[20px] md:rounded-[25px] m-[2px] text-white flex place-items-center justify-center ease-in duration-300 bg-vdv-base;
}
.points {
  @apply font-bold flex-row leading-3 md:leading-5 text-center text-sm md:text-md;
}
</style>
